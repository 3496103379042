<template>
  <b-row
    align-h="center"
    class="mt-2"
  >
    <b-col
      cols="12"
      lg="10"
      md="10"
    >
      <budget-preview />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BudgetPreview from '@/views/drs/budgets/budgets-preview/BudgetPreview.vue'

export default {
  components: {
    BudgetPreview,
    BRow,
    BCol,
  },
}
</script>
